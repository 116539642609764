<template>
  <Dialog :visible="isVisible" :modal="true" header="Añadir manual" @update:visible="closeDialog">
    <form @submit.prevent="onSubmit">
      <div class="grid items-center">
        <div class="col-12 md:col-2">Desde</div>
        <div class="col">
          <div class="flex gap-1">
            <div class="flex flex-1">
              <Calendar id="calendar-24h" v-model="inputs.dateFrom" hour-format="24" />
            </div>
            <div class="flex flex-1">
              <Calendar id="calendar-timeonly" v-model="inputs.dateFrom" time-only />
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-1 items-center">
        <div class="col-12 md:col-2">Hasta</div>
        <div class="col">
          <div class="flex gap-1">
            <div class="flex flex-1">
              <Calendar
                id="calendar-24h"
                v-model="inputs.dateTo"
                :min-date="minDate"
                hour-format="24"
              />
            </div>
            <div class="flex flex-1">
              <Calendar
                id="calendar-timeonly"
                v-model="inputs.dateTo"
                :min-date="minDate"
                time-only
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-1 items-center">
        <div class="col-12 md:col-2">Motivo</div>
        <div class="col">
          <Dropdown
            v-model="inputs.reason"
            :options="reasonOptions"
            option-label="label"
            option-value="value"
            placeholder="Motivo"
            class="w-full"
          />
          <span v-if="errors.reason" class="text-red text-xs">{{ errors.reason }}</span>
        </div>
      </div>
      <div class="grid mt-3 items-center">
        <div class="col-12 md:col-2"></div>
        <div class="col">
          <div class="flex flex-nowrap justify-content-start align-items-center">
            <div class="flex flex-initial">
              <Checkbox v-model="inputs.agree" :binary="true" name="agree" input-id="agree" />
            </div>
            <div class="flex flex-initial ml-2 text-red font-bold">
              Marque esta casilla para confirmar lo que está haciendo
            </div>
          </div>
          <span v-if="errors.agree" class="text-red text-xs">{{ errors.agree }}</span>
        </div>
      </div>

      <div class="grid mt-3 items-center">
        <div class="col-12 md:col-2"></div>
        <div class="col">
          <Button  data-testid="add-event-button" :loading="loading" :disabled="!inputs.agree && !loading" type="submit" :label="loading ? 'Añadiendo...' : 'Añadir'" />
        </div>
      </div>
    </form>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

import type { EventCalendar, FormEventCalendarErrors } from '@/types/bookings_manager/persons/Calendar'

type ReasonOption = {
  label: string;
  value: string;
}

const errors = ref<FormEventCalendarErrors>({})
const props = defineProps<{
  show: boolean
  loading: boolean
  eventCalendar: EventCalendar
  reasonOptions: ReasonOption[]
}>()

const emit = defineEmits([
  'update:show',
  'update:eventCalendar',
  'update:loading',
  'submitEvent',
  'addEvent',
  'removeEvent'
])

watch(
  () => props.eventCalendar.dateFrom,
  (newValue, oldValue) => {
    if (isEditable.value && newValue > oldValue) {
      const dateTo = new Date(newValue.getTime() + 30 * 60 * 1000)
      inputs.value.dateTo = dateTo
    }
    if (newValue && !isEditable.value) {
      const dateTo = new Date(newValue.getTime() + 30 * 60 * 1000)
      inputs.value.dateTo = dateTo
    }
  }
)

const isVisible = computed({
  get: () => props.show,
  set: (value) => emit('update:show', value)
})

const isEditable = computed(() => {
  return props.eventCalendar.id
})

const inputs = computed({
  get: () => props.eventCalendar,
  set: (value) => emit('update:eventCalendar', value)
})

const minDate = computed(() => {
  return inputs.value.dateFrom
})

/**
 * Closes the dialog by setting the visibility to false.
 *
 */
const closeDialog = () => {
  isVisible.value = false
}

/**
 * Validates the form event person inputs and returns any errors found.
 *
 * @return {Partial<FormEventCalendarErrors>} Any validation errors found.
 */
const validate = () => {
  const errors: Partial<FormEventCalendarErrors> = {}

  if (!inputs.value.reason) {
    errors.reason = 'Este campo es requerido'
  }

  if (!inputs.value.agree) {
    errors.agree = 'Este campo es requerido'
  }

  return errors
}

/**
 * Submit the form data after validation and update the availability for the driver.
 *
 * @return {boolean} Returns false if there are input errors, otherwise returns nothing.
 */
const onSubmit = async () => {
  const inputErrors = validate()
  if (Object.keys(inputErrors).length > 0) {
    errors.value = inputErrors
    return false
  }

  emit('update:loading', true)
  emit('submitEvent', inputs.value)

  errors.value = {}
  // isVisible.value = false
}
</script>
