import { defineStore } from 'pinia'
import { ref, computed, toRef } from 'vue'
import type { Flights, AenaFlights } from '@/types/flights/flights'
import { getAirportData } from '@/services/flights/fetchAirportData'
import { useToast } from 'primevue/usetoast'
import { showError, showSuccess } from '@/utils/errors'
import { formatDate } from '@/utils/bookings/bookings'
import { useSharedDateStore } from '../sharedDateStore'

export const useFlightStore = defineStore('flights', () => {
  const toast = useToast()

  const flightsByAirport = ref({} as { [key: string]: Flights })
  const filteredFlights = ref([] as Flights)
  const loading = ref(true)
  const date = toRef(useSharedDateStore(), 'date') // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

  let firstLoad = true

  const fetchAirportData = async () => {
    if (firstLoad) {
      loading.value = true
    }
    try {
      // const fetchedFlights = await getAirportData(formatDate(date.value))

      // Object.entries(fetchedFlights).forEach(([key, value]) => {
      //   flightsByAirport.value[key] = mapData(value)
      // })
      // // Populate filteredFlights with all flights after fetching
      // filteredFlights.value = Object.values(flightsByAirport.value)
      //   .flat(2)
      //   .sort((a, b) =>
      //     a.horaProgramada > b.horaProgramada ? 1 : a.horaProgramada < b.horaProgramada ? -1 : 0
      //   )

      showSuccess('Correcto!', 'Se han cargado los vuelos de todos los aeropuertos', toast)
    } catch (error) {
      showError(error, toast)
    }

    if (firstLoad) {
      loading.value = false
      firstLoad = false
    }
  }

  const resetDate = () => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate())
    date.value = currentDate
  }

  const fetchAirportDataByDate = async (inputDate: Date) => {
    loading.value = true
    try {
      // Convert inputDate to the format required by getAirportData.
      const fetchedFlights = await getAirportData(formatDate(inputDate))

      Object.entries(fetchedFlights).forEach(([key, value]) => {
        flightsByAirport.value[key] = mapData(value)
      })

      // Populate filteredFlights with all flights after fetching
      filteredFlights.value = Object.values(flightsByAirport.value)
        .flat(2)
        .sort((a, b) =>
          a.horaProgramada > b.horaProgramada ? 1 : a.horaProgramada < b.horaProgramada ? -1 : 0
        )

      showSuccess('Correcto!', 'Se han cargado los vuelos de todos los aeropuertos', toast)
    } catch (error) {
      showError(error, toast)
    }

    loading.value = false
  }

  const mapData = (flightsAena: AenaFlights): Flights => {
    if (typeof flightsAena === 'string') return []
    return flightsAena.map((flight) => ({
      id: flight.id,
      compania: flight.compania,
      numVuelo: flight.numVuelo,
      iataAena: flight.iataAena,
      fecha: flight.fecha,
      horaEstimada: flight.horaEstimada,
      horaProgramada: flight.horaProgramada,
      estado: flight.estado,
      nombreCompania: flight.nombreCompania
    }))
  }

  const filterFlightsByAirport = (airport: string): void => {
    filteredFlights.value = (
      airport === 'Todos'
        ? Object.values(flightsByAirport.value).flat(2)
        : flightsByAirport.value[airport] || []
    ).sort((a, b) =>
      a.horaProgramada > b.horaProgramada ? 1 : a.horaProgramada < b.horaProgramada ? -1 : 0
    )
  }

  const startFetchingData = () => {
    fetchAirportData()
    setInterval(fetchAirportData, 60 * 1000 * 5)
  }

  return {
    fetchAirportDataByDate,
    resetDate,
    startFetchingData,
    flightsByAirport,
    fetchAirportData,
    loading,
    date,
    filterFlightsByAirport,
    filteredFlights
  }
})
