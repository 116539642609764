import { ref, watch } from 'vue'
import type { Ref } from 'vue'

import type { DateSelectArg, EventClickArg } from '@fullcalendar/core'
import type { CalendarOptions, EventInput } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'

import type { EventCalendar } from '@/types/bookings_manager/persons/Calendar'

export function useComposableCalendar(
  refCalendar: Ref<InstanceType<typeof FullCalendar> | null>,
  events: Ref<EventInput[]>
) {
  const today: Ref<Date> = ref(new Date())
  const showDialogEvent = ref(false)
  const eventCalendar = ref<EventCalendar>({} as EventCalendar)

  watch(events, () => {
    refCalendar.value?.getApi().removeAllEvents()
    for (const event of events.value) {
      refCalendar.value?.getApi().addEvent(event)
    }
  })


  /**
   * Show dialog to add a new event to the calendar
   *
   * @param {DateSelectArg} selectInfo - SelectInfo from FullCalendar
   * @return {void}
   */
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    eventCalendar.value.dateFrom = selectInfo.start
    eventCalendar.value.dateTo = selectInfo.end
    eventCalendar.value.reason = ''
    eventCalendar.value.agree = false
    showDialogEvent.value = true
  }

  const handleEventClick = (eventInfo: EventClickArg) => {
    if (!eventInfo.event.extendedProps.isEditable) return
    if (eventInfo.event.startStr !== null && eventInfo.event.endStr !== null) {
      eventCalendar.value.id = eventInfo.event.id
      eventCalendar.value.dateFrom = new Date(eventInfo.event.startStr)
      eventCalendar.value.dateTo = new  Date(eventInfo.event.endStr)
    }
    eventCalendar.value.reason = eventInfo.event.title
    eventCalendar.value.agree = false

    showDialogEvent.value = true
  }

  /**
   * Handle adding an event based on the provided data.
   *
   * @param {EventCalendar} data - the data of the event to be added
   * @return {void}
   */
  const handleAddEvent = (data: EventCalendar) => {
    refCalendar.value?.getApi().addEvent({
      id: data.id,
      title: data.reason,
      start: data.dateFrom,
      end: data.dateTo,
      color: '#EB8C8C',
      editable: true,
      serviceType: data.serviceType,
      isEditable: true
    })
  }

  const handleRemoveEvent = (id: string) => {
    refCalendar.value?.getApi().getEventById(id)?.remove()
  }

  const calendarOptions = {
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin // needed for dateClick
    ],
    initialView: 'timeGridDay',
    initialDate: today.value,
    initialEvents: [],
    eventColor: '#EB8C8C',
    eventTextColor: '#000000',
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    weekends: true,
    locale: esLocale,
    allDaySlot: false,
    height: '85vh',
    headerToolbar: {
      left: 'title',
      center: '',
      right: ''
    },
    viewClassNames: 'border-calendar',
    // slotEventOverlap: false,
    eventClick: handleEventClick,
    select: handleDateSelect,
    eventDrop: handleEventClick,
    eventResize: handleEventClick,
    eventOverlap: function(stillEvent, movingEvent) {
      if (!stillEvent || !movingEvent) return false
      // If event is displayed in background, can move it.
      if (stillEvent.display === 'background') return true
      // If event is displayed in block, can't move it.
      return false
    },
  } as CalendarOptions

  return {
    showDialogEvent,
    eventCalendar,
    handleAddEvent,
    calendarOptions,
    handleDateSelect,
    handleRemoveEvent
  }
}
