import api from "@/boot/axios";
import type { Driver } from "@/types/bookings_manager/persons/Person";
import type {
  Availability,
  AvailabilityForm,
  AvalabilityGenerator,
} from "@/types/drivers";

import authHeader from "../authHeaderService";
import { DRIVER_URL } from "@/consts/drivers/drivers";

export default function driverService() {
  const uploadFunc = async (driver: Driver) => {
    const response = await api.post<Driver>(DRIVER_URL, driver, {
      headers: authHeader(),
    });
    return response.data;
  };
  const getFunc = async (id: number) => {
    const response = await api.get<Driver>(`${DRIVER_URL}/${id}`);
    return response.data;
  };
  const getAllFunc = async () => {
    const response = await api.get<Driver[]>(DRIVER_URL, {
      headers: authHeader(),
    });
    return response.data;
  };
  const updateFunc = async (id: number, person: Driver) => {
    const response = await api.put<Driver>(DRIVER_URL + "/" + id, person, {
      headers: authHeader(),
    });
    return response.data;
  };
  const deleteMultipleFunc = async (itemIds: number[]) => {
    const deletedItems = [];
    for (const id of itemIds) {
      try {
        deletedItems.push(await deleteSingleFunc(id));
      } catch (error) {
        // Handle errors here if needed
        console.error(`Error deleting item with ID ${id}:`, error);
      }
    }
    return deletedItems;
  };
  const deleteSingleFunc = async (itemId: number) => {
    const response = await api.delete<Driver>(DRIVER_URL + "/" + itemId, {
      headers: authHeader(),
    });
    return response.data;
  };

  const getAvailability = async (
    idDriver: number,
    date: string,
    scope = "DAY"
  ) => {
    const response = await api.get<Availability[]>(
      `${DRIVER_URL}/${idDriver}/availability/${date}/${scope}`
    );
    return response.data;
  };

  const getServices = async (
    idDriver: number,
    date: string,
    scope = "DAY"
  ) => {
    const response = await api.get<any[]>(
      `${DRIVER_URL}/${idDriver}/services/${date}/${scope}`
    );
    return response.data;
  };

  const uploadAvailability = async (
    idDriver: number,
    availability: AvailabilityForm
  ) => {
    const response = await api.post<Availability>(
      `${DRIVER_URL}/${idDriver}/availability`,
      availability
    );
    return response.data;
  };

  const updateAvailability = async (
    idDriver: number,
    idAvailability: string,
    availability: AvailabilityForm
  ) => {
    const response = await api.put<Availability>(
      `${DRIVER_URL}/${idDriver}/availability/${idAvailability}`,
      availability
    );
    return response.data;
  };

  const generateAvailability = async (
    idDriver: number,
    availability: AvalabilityGenerator
  ) => {
    const response = await api.post<Availability>(
      `${DRIVER_URL}/${idDriver}/availability/generate`,
      availability
    );
    return response.data;
  };

  return {
    uploadFunc,
    getFunc,
    getAllFunc,
    updateFunc,
    deleteMultipleFunc,
    deleteSingleFunc,
    getAvailability,
    getServices,
    uploadAvailability,
    updateAvailability,
    generateAvailability,
  };
}
