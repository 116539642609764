<template>
  <div v-if="isLoading" class="flex justify-content-center items-center h-screen row">
    <ProgressSpinner style="width: 70px; height: 70px" />
  </div>
  <div v-else class="grid">
    <div class="col-12 lg:col-4">
      <div class="border-round-sm text-left left-calendar mb-4">
        <h4 class="driver-name mb-1">{{ driverFullName }}</h4>
        <h5 class="driver-number m-0">Chofer {{ driverDetail?.id }}</h5>
        <div class="grid mt-5">
          <div class="col-auto lg:col-12">
            <Calendar v-model="today" inline />
          </div>
          <div class="col lg:col-12">
            <div class="grid gap-1 w-full">
              <div class="mt-2 pl-2">
                <Button label="Regenerar" @click="showRegenerateDialog = true"/>
              </div>
              <div class="mt-2 pl-2">
                <Button label="Añadir manual" @click="setDateNewEvent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-8 mt-5 calendar-container">
      <div class="demo-app-main">
        <FullCalendar ref="refCalendar" class="demo-app-calendar" :options="calendarOptions">
          <template #eventContent="arg">
            <div data-testid="hola">
            <span v-if="arg.event.extendedProps.isEditable"><i class="fa-regular fa-pen-to-square"></i> </span>
            {{ arg.timeText }}
            <b>{{ arg.event.title }}</b>
            </div>
          </template>
        </FullCalendar>
      </div>
    </div>

    <NewEventCalendar
      v-model:show="showDialogEvent"
      v-model:loading="isLoadingEvents"
      :event-calendar="eventCalendar"
      :reason-options="reasonOptions"
      @submit-event="handleSubmitEvent"
    />

    <RegenerateCalendar
      v-model:show="showRegenerateDialog"
      v-model:loading="isLoadingEvents"
      @regenerate-calendar="handleRegenerateCalendar"
    />

  </div>

  <div v-if="isLoadingEvents" class="fixed top-0 left-0 w-full bg-transparent text-center p-4 z-50">
    <ProgressSpinner
      style="width: 20px; height: 20px"
      stroke-width="8"
      fill="var(--surface-ground)"
      loading-text="Fetching data..."
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { AxiosError } from 'axios'

import { useToast } from 'primevue/usetoast'
import Calendar from 'primevue/calendar'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner'

import FullCalendar from '@fullcalendar/vue3'
import type { EventInput } from '@fullcalendar/core'

import type { Driver } from '@/types/bookings_manager/persons/Person'
import type { Availability } from '@/types/drivers'
import { showError, showSuccess } from '@/utils/errors'
import { useComposableCalendar } from '@/composables/useComposableCalendar'
import router from '@/router'
// import personService from '@/services/bookings_manager/personService'
import driverService from '@/services/bookings_manager/driverService'
import NewEventCalendar from '@/components/bookings/NewEventCalendar.vue'
import RegenerateCalendar from '@/components/bookings/RegenerateCalendar.vue'
import { formatDate } from '@/utils/bookings/bookings'
import { format } from "@formkit/tempo"
import type { EventCalendar } from '@/types/bookings_manager/persons/Calendar'

const toast = useToast()

const refCalendar = ref<InstanceType<typeof FullCalendar> | null>(null)
const driverDetail = ref<Driver>({} as Driver)
const availability = ref<Availability[]>([])
const events = ref<EventInput[]>([])
const isLoading = ref(false)
const isLoadingEvents = ref(false)
const showRegenerateDialog = ref(false)
const today = ref(new Date())
const { calendarOptions, showDialogEvent, eventCalendar, handleAddEvent, handleRemoveEvent } =
useComposableCalendar(refCalendar, events)

const reasonOptions = [
  { label: 'Comida', value: 'Comida' },
  { label: 'Cena', value: 'Cena' },
  { label: 'Asunto personal', value: 'Asunto personal' },
  { label: 'Indisposición', value: 'Indisposición' }
]

watch(today, async () => {
  isLoadingEvents.value = true
  refCalendar.value?.getApi().gotoDate(formatDate(today.value))
  availability.value = await driverService().getAvailability(
    driverId.value,
    formatDate(today.value)
  )
  events.value = eventsDirver(availability.value)
  isLoadingEvents.value = false
})

const driverId = computed(() => {
  return Number(router.currentRoute.value.params.id)
})

const driverFullName = computed(() => {
  return `${driverDetail.value?.employee?.name} ${driverDetail.value?.employee?.surname}`
})

onMounted(async () => {
  isLoading.value = true
  try {
    driverDetail.value = await driverService().getFunc(driverId.value)
    // TODO: Currently, assignment of the date is performed to trigger the watch and add events
    // Search for a better solution. **Pending**
    today.value = new Date()
    events.value = eventsDirver(availability.value)
  } catch (error) {
    showError(error, toast)
  } finally {
    isLoading.value = false
  }
})

/**
 * Sets the date and time for a new event by rounding to the nearest half hour.
 *
 */
const setDateNewEvent = () => {
  showDialogEvent.value = true

  const now = new Date()
  const roundedHour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours())
  const roundedHalfHour = new Date(roundedHour.getTime() + 30 * 60 * 1000)

  eventCalendar.value.dateFrom = roundedHalfHour
  eventCalendar.value.dateTo = new Date(roundedHalfHour.getTime() + 30 * 60 * 1000)
  eventCalendar.value.reason = ''
  eventCalendar.value.agree = false
}

const eventsDirver = (availability: Availability[]) => {
  return availability.map((event: Availability) => {
    return {
      id: String(event.id),
      title: event.reason,
      start: new Date(event.startDate),
      startStr: event.startDate,
      endStr: event.endDate,
      end: new Date(event.endDate),
      color: event.isWorking ? '#9F9F9F' : '#EB8C8C',
      display: event.isWorking ? 'background' : 'block',
      isEditable: event.isWorking ? false : true
    }
  })
}

const handleSubmitEvent = async (data: EventCalendar) => {
  isLoadingEvents.value = true
  let response: Availability;
  try {
    if (eventCalendar.value.id) {
      response = await driverService().updateAvailability(driverId.value, data.id, {
        startDate: format(data.dateFrom, 'YYYY-MM-DDTHH:mm:ss'),
        endDate: format(data.dateTo, 'YYYY-MM-DDTHH:mm:ss'),
        reason: data.reason
      })

      handleRemoveEvent(String(data.id))
    } else {
      response = await driverService().uploadAvailability(driverId.value, {
        startDate: format(data.dateFrom, 'YYYY-MM-DDTHH:mm:ss'),
        endDate: format(data.dateTo, 'YYYY-MM-DDTHH:mm:ss'),
        reason: data.reason
      })
    }

    const reason = reasonOptions.find((option) => option.value === data.reason)
    if (!reason) return
    const event = { ...data, reason: reason.label, id: String(response.id)  }

    handleAddEvent(event)

    eventCalendar.value = {} as EventCalendar
    showDialogEvent.value = false
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      showError(error.response.data.error.message, toast)
    } else {
      showError(error, toast)
    }
    return
  } finally {
    isLoadingEvents.value = false
  }
}


const handleRegenerateCalendar = async (startDate: Date, endDate: Date) => {
  isLoadingEvents.value = true
  try {
     await driverService().generateAvailability(driverId.value, {
      startDate: format(startDate, 'YYYY-MM-DDTHH:mm:ss'),
      endDate: format(endDate, 'YYYY-MM-DDTHH:mm:ss')
    })
    showSuccess('Calendario', 'El calendario se ha regenerado correctamente.', toast)
    today.value = new Date()
  } catch (error) {
    showError(error, toast)
  }  finally {
   isLoadingEvents.value = false
  }
}
</script>

<style lang="css">
.calendar-container {
  height: 85vh;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
  /* padding: 1em; */
}

.fc {
  /* the calendar root */
  /* max-width: 1100px; */
  margin: 0 auto;
  position: relative;
}

.border-calendar {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.border {
  border: 1px solid #dee2e6;
}
</style>
