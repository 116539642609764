import router from '@/router'
import axios from 'axios'
import { Notify } from 'quasar'

interface ValidationError {
  message: string
  error: Record<string, string>
}

/**
 * Handles HTTP errors and displays appropriate messages.
 *
 * @param {unknown} error - the error to be handled
 * @return {void}
 */
export const httpErrorHandler = async (error: unknown) => {
  if (axios.isAxiosError<ValidationError, Record<string, unknown>>(error)) {
    if (!error.response) {
      console.log('Sin respuesta del servidor: ', String(error))
      return
    }

    if (!error.response?.data) {
      console.log('La respuesta no tiene datos: ', String(error))
      return
    }

    if (
      error.response?.status !== undefined &&
      error.response?.status >= 500 &&
      error.response?.status < 600
    ) {
      Notify.create({
        message: `Server error.`,
        color: 'negative'
      })
      return
    }

    if (error.response?.status === 401) {
      let message = ''
      if (error.response?.data.error.message) {
        message = error.response?.data.error.message
      } else {
        message = JSON.stringify(error.response?.data.error)
      }
      
      console.log(message)
      Notify.create({
        message: String(message),
        color: 'negative',
        position: 'top'
      })
      router.push({ name: 'logout' })
      return
    }

    if (error.response?.status === 403) {
      const message = error.response.data.error.message.toString()
      Notify.create({
        message: message,
        color: 'negative',
        position: 'top'
      })
      return
    }

    if (error.response?.data) {

      const data = error.response?.data

      if (data.error && typeof data.error === 'object') {
        const message = error.response?.data.error['message']
        Notify.create({
          message: message,
          color: 'negative',
          position: 'top'
        })
        return
      }

      if (data.error && typeof data.error === 'string') {
        Notify.create({
          message: data.error,
          color: 'negative',
          position: 'top'
        })
        return
      }

      if (data && typeof data === 'object') {
        for (const [key, value] of Object.entries(data)) {
          Notify.create({
            message: `${key}: ${value}`,
            color: 'negative',
            position: 'top'
          })
        }
        return
      }
    }
  } else {
    console.log('Error desconocido: ', String(error))
  }
}
