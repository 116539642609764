import { defineStore } from 'pinia'
import { ref } from 'vue'

import { loginService, logoutService, validateCookie } from '@/services/authService'
import type { LoginFormParams } from '@/types/types'
import { httpErrorHandler } from '@/utils/httpErrorHandler'

import ability from '@/services/ability'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const password = ref('')
    const email = ref('')
    const roles = ref([''])
    const id = ref(0)
    const isUserAuthenticated = async () => {

      try {
        await validateCookie()
        return true
      } catch (err) {
        httpErrorHandler(err)
      }
    }

    const login = async (data: LoginFormParams) => {
      const response = await loginService(data)
      id.value = response.id
      password.value = data.password
      email.value = response.email
      roles.value = response.roles

      updateAbility()
      return response
    }

    const logout = async () => {
      roles.value = []
      return await logoutService()
    }

    const updateAbility = () => {
      const permissions = []
      for (const role of roles.value) {
        const items = role.split('_')
        permissions.push({
          fields: String(items[0]).toLowerCase(),
          action: String(items[1]).toLowerCase(),
          subject: String(items[2]).toLowerCase()
        })
      }
      ability.update(
        permissions
      )
    }

    return {
      id,
      email,
      password,
      roles,
      isUserAuthenticated,
      updateAbility,
      login,
      logout
    }
  },
  {
    persist: true
  }
)
