import api from '@/boot/axios'

import type {
  LoginFormParams,
  ForgotPasswordFormParams,
  ResetPasswordParams,
  User,
  UserOutput,
  Roles,
  LoginResponse
} from '@/types/types'
import {
  LOGIN,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSOWRD,
  GET_ALL_USERS,
  UPDATE_USER,
  CREATE_USER,
  GET_ALL_ROLES, VALIDATE_TOKEN
} from '@/consts/authUrls'

/**
 * Service to login user
 *
 * @param data
 * @returns session data
 * @throws Error
 */
export async function loginService(data: LoginFormParams): Promise<LoginResponse> {
  const response = await api.post<LoginResponse>(LOGIN, data)
  return response.data
}

export async function logoutService(): Promise<void> {
  await api.delete<void>(LOGOUT)
}

export async function forgotPasswordService(data: ForgotPasswordFormParams) {
  return await api.post<void>(FORGOT_PASSWORD, data)
}

export async function resetPasswordService(data: ResetPasswordParams) {
  return await api.post<void>(RESET_PASSOWRD, data)
}

export async function getAllUsersService(): Promise<User[]> {
  const response = await api.get<User[]>(GET_ALL_USERS)
  return response.data
}

export async function updateUserService(idUser: number, data: UserOutput): Promise<User> {
  const response = await api.patch<User>(`${UPDATE_USER}/${idUser}`, data)
  return response.data
}

export async function createUserService(data: User): Promise<User> {
  const response = await api.post<User>(CREATE_USER, {...data, roleNames: data.roles})
  return response.data
}

export async function getAllRolesService(): Promise<Roles[]> {
  const response = await api.get<Roles[]>(GET_ALL_ROLES)
  return response.data
}

export async function validateCookie() {
  return await api.get<void>(VALIDATE_TOKEN)
}
