import '@/boot/init'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { primeVueconfig } from './config/primevueConfig'
import { Quasar } from 'quasar'

// Import Quasar dependencies
import '@quasar/extras/material-icons/material-icons.css'
import 'quasar/src/css/index.sass'
import { Notify, Loading, Dialog } from 'quasar'

// Import Casl dependencies for permissions
import { abilitiesPlugin } from '@casl/vue'
import ability  from '@/services/ability'

import PrimeVue from 'primevue/config'
import BadgeDirective from 'primevue/badgedirective'
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'

import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import CodeHighlight from '@/components/CodeHighlight.vue'
import BlockViewer from '@/components/BlockViewer.vue'

// Styles for  PrimeVue
import '@/assets/styles.scss'

import App from './App.vue'
import router from './router/index'
const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(PrimeVue, primeVueconfig)
app.use(ToastService)
app.use(DialogService)
app.use(ConfirmationService)

app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)
app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)

app.component('CodeHighlight', CodeHighlight)
app.component('BlockViewer', BlockViewer)

app.use(Quasar, {
  plugins: { Notify, Loading, Dialog }
})

app.use(abilitiesPlugin, ability)

app.mount('#app')
